import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import Header from '../components/header'
import Footer from '../components/footer'
import facebooklogo from '../assets/images/icon/facebook.svg'
import linkedinlogo from '../assets/images/icon/linkedin.svg'
import knsicongrey from '../assets/images/icon/kns-icon-grey.svg'
import LocalizedLink from '../LocalizedLink'

class CareerDetail extends React.Component {
  componentDidMount() {
    let $ = window.$
    $('.header').css('position', 'absolute')
  }
  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="other-page">
          <Header logo="kns-blue.svg" location={this.props.location.pathname} />
          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <section className="page">
            <div className="container">
              <div className="col-12">
                <figure className="kns-grey-icon">
                  <img src={knsicongrey} />
                </figure>
                <div className="page-prp">
                  <h1>
                    <FormattedMessage id="Career" />
                  </h1>
                  {this.props.pageContext.locale == 'tr' && (
                    <>
                      <p>Are you ready to join the team?</p>
                    </>
                  )}
                  {this.props.pageContext.locale == 'en' && (
                    <>
                      <p>Are you ready to join the team?</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="about">
            <div className="container">
              <figure className="about-image">
                <div className="about-prp">
                  <aside className="numbers">
                    <h1>Full Stack Developer</h1>
                  </aside>
                </div>
              </figure>
              {this.props.pageContext.locale == 'tr' && (
                <>
                  <article class="job-item">
                    <h3>Development Team</h3>
                    <div class="jb">
                      <a href="#">
                        Javascript Developer <span>Intern</span>
                      </a>
                    </div>
                  </article>
                </>
              )}

              {this.props.pageContext.locale == 'en' && (
                <>
                  <section className="job-detail">
                    <p>
                    KNS Information Technologies, the leading digital marketing solution provider for venues and retail businesses, is looking for Full Stack Developer tohelp us maintain existing Node.js (Hapi.js) projects.
                    </p>

                    <p>
                    This is a great opportunity to join a rapidly expanding IT company working with companies from all around the world. We are looking for a team member who has experience and enthusiasm for developing software.
                    </p>

                    <p>We've got some really exciting upcoming and ongoing projects for the venues, indoor mapping, and navigation with new promising features, and we are looking for Node.JS developers at all levels of seniority to develop solid, scalable, high-performance platforms for the web using up-to-date Javascript technologies.</p>

                    <ul>
                  <li>You should be a team player, energetic and motivated because we want you to adapt to our dynamic and lively environment for challenging tasks and agile development.</li>
                  <li>You should also write well-documented, clean code, and should have a constant interest to follow best practices and to keep up with JS technologies.</li>
                    </ul>


                    <h4 className="cd-t">
                      Essential Skills and Qualifications:
                    </h4>

                    <ul>
                      <li>BS or MS in Computer Science or related field</li>
                      <li>Strong JavaScript, TypeScript programming skills</li>
                      <li>Experience in Node.js and its MVC frameworks (Express JS, Koa.js, Hapijs, NestJS etc.)</li>
                      <li>Knowledge of consuming and creating web services (Restful API)</li>
                      <li>Experience with frontend web development using HTML, CSS, and JavaScript – familiarity with ReactJS or other frameworks is a plus.</li>
                      <li>Good understanding of server-side rendering and templating languages</li>
                      <li>Integration of elements developed by front-end developers with server-side logic</li>
                      <li>Experience in No-SQL databases (especially MongoDB, Redis)</li>
                      <li>Experience in No-SQL databases (especially MongoDB, Redis)</li>
                      <li>At least 2 years of experience coding professionally</li>
                      <li>Technical English proficiency is a must</li>
                      <li>No military obligation for male candidates</li>
                    </ul>

                    <h4 className="cd-t">Bonuses:</h4>

                    <ul>
                      <li>Automation/Build Tools (Webpack, Parcel, etc)</li>
                      <li>Being an agile-minded team player</li>
                      <li>Having good communication skills</li>
                      <li>Well-organized, creative and results-oriented</li>
                      <li>Ability to multitask and prioritize daily workload</li>
                      <li>Unit Testing</li>
                    </ul>

                    <h4 className="cd-t">Opportunities:</h4>

                    <ul>
                     <li>Ability to work remotely during COVID19 Pandemic</li>
                     <li>Working with talented seniors</li>
                     <li>Dealing with brand new technologies</li>
                     <li>Good hardware options to work with (2 external monitors, standing desk option, etc.)</li>
                     <li>Flexible and relaxed working environment</li>
                     <li>Being in an international team working with Italy, Dubai, Russia, etc</li>
                    </ul>

                    <h2 class="brd-top">
                      Apply today and hear from us tomorrow
                    </h2>

                    {/* <Link href="mailto:onur.durmus@kns.com.tr?subject=cv" class="apply-job">APPLY FOR THIS JOB</Link> */}

                    <a
                      href="mailto:info@kns.com.tr?subject=Full Stack Developer"
                      class="apply-job"
                    >
                      APPLY FOR THIS JOB
                    </a>
                  </section>
                </>
              )}
            </div>
          </section>

          <Footer />
        </body>
      </Layout>
    )
  }
}
CareerDetail.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export default CareerDetail
